.wrapper {
  display: block;
  position: relative;
  margin: 60px auto;
  text-align: center;
  max-width: 800px;
  width: 100%;
  overflow: auto;
}
.device_wrapper {
  float: right;
  position: relative;
  width: 60%;
  min-width: 300px;
  right: 6%;
  margin: 0;
}
.device_wrapper.angled {
  float: left;
  position: absolute;
  width: 55%;
  min-width: 260px;
  left: 6%;
  margin: 35px 0 0;
  -webkit-perspective: 400px;
  -moz-perspective: 400px;
  -ms-perspective: 400px;
  -o-perspective: 400px;
  -webkit-transform-style: preserve 3d;
  -moz-transform-style: preserve 3d;
  -ms-transform-style: preserve 3d;
  -o-transform-style: preserve 3d;
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  -ms-transition: all 400ms ease;
  -o-transition: all 400ms ease;
}
.device_wrapper.angled:hover {
  -webkit-transform: translate(-2%, 0);
  -moz-transform: translate(-2%, 0);
  -ms-transform: translate(-2%, 0);
  -o-transform: translate(-2%, 0);
}
.device_wrapper.angled .device {
  -webkit-transform: rotateY(5deg) scaleX(0.9);
  -moz-transform: rotateY(5deg) scaleX(0.9);
  -ms-transform: rotateY(5deg) scaleX(0.9);
  -o-transform: rotateY(5deg) scaleX(0.9);
  -webkit-transition: all 400ms ease 200ms;
  -moz-transition: all 400ms ease 200ms;
  -ms-transition: all 400ms ease 200ms;
  -o-transition: all 400ms ease 200ms;
}
.device_wrapper.angled:hover .device {
  -webkit-transform: rotateY(0) scaleX(1);
  -moz-transform: rotateY(0) scaleX(1);
  -ms-transform: rotateY(0) scaleX(1);
  -o-transform: rotateY(0) scaleX(1);
  -webkit-box-shadow: 0 0 0 transparent, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0 !important;
  -moz-box-shadow: 0 0 0 transparent, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0 !important;
  box-shadow: 0 0 0 transparent, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0 !important;
}
.device_wrapper.angled .device.light {
  -webkit-box-shadow: -15px 0 0 #d9d9d9, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0;
  -moz-box-shadow: -15px 0 0 #d9d9d9, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0;
  box-shadow: -15px 0 0 #d9d9d9, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0;
}
.device {
  width: 88%;
  padding: 1% 3% 3%;
  -webkit-border-radius: 3em;
  -moz-border-radius: 3em;
  -o-border-radius: 3em;
  border-radius: 3em;
  .mobile-device-camera {
    width: 20px;
    height: 20px;
    position: absolute;
    content: '';
    background: #444;
    border-radius: 50%;
    border: solid 8px #262525;
    top: 15px;
    left: calc(50% - 10px);
  }
  .speaker {
    display: block;
    position: relative;
    width: 25%;
    min-height: 8px;
    margin: 0 auto 2%;
    -webkit-border-radius: 1em;
    -moz-border-radius: 1em;
    -o-border-radius: 1em;
    border-radius: 1em;
  }
  img {
    display: block;
    position: relative;
    width: 100%;
    border-radius: 2em;
  }
  &.dark {
    background: #222222;
    border: 4px solid #1c1c1c;
    .speaker {
      background: #000;
      border-bottom: 1px solid #444;
    }
    img {
      -webkit-box-shadow: 0 0 0 2px #0c0b0b, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0;
      -moz-box-shadow: 0 0 0 2px #0c0b0b, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0;
      box-shadow: 0 0 0 2px #0c0b0b, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0;
    }
  }
  &.light {
    background: #f3f3f3;
    border: 4px solid #d9d9d9;
    .speaker {
      background: #d9d9d9;
      img {
        -webkit-box-shadow: 0 0 0 4px #d9d9d9, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0;
        -moz-box-shadow: 0 0 0 4px #d9d9d9, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0;
        box-shadow: 0 0 0 4px #d9d9d9, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .device_wrapper,
  .device_wrapper.angled {
    display: block;
    float: none;
    position: relative;
    left: 0;
    width: 94%;
    min-width: 300px;
    margin: 0 2%;
    text-align: center;
  }
  .device_wrapper.angled {
    margin: 30px 2% 60px;
  }
  .device_wrapper.angled:hover {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
  }
}

