// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) {
  .wrapper {
    max-width: 450px;
    margin: 0 auto;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .wrapper {
    max-width: 450px;
    margin: 0 auto;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

}

// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {

}
// Large devices (desktops, 992px and up)
@media (max-width: 992px) {
  .screenshot {
    position: inherit;
    margin-bottom: 2rem;
  }
  .wrapper {
    .device_wrapper {
      min-width: 100px;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .screenshot {
    position: absolute;
  }

}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .wrapper {
    max-width: 600px;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
  .wrapper {
    max-width: 800px;
  }
}
