body {
  background-color: lightgray;
}

.header {
  min-height: 320px;
  background-color: #000000;
  position: relative;
  /*для нормальных браузеров*/
  clip-path: polygon(0% 0%, 100% 0%, 100% 38%, 0% 100%);
  //background-image: url("./assets/speedometr-banner.jpg");
  background-image: url("./assets/speedometr.png");
  background-repeat: no-repeat;
  //background-size: contain;
  background-position: 4% 26%;
}

.screenshot {
  position: absolute;
  top: 50px;
  right: 0px;
}
.play-btn {
  img {width: 300px;}
}

.features-list {
  .feature-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    background: #fdf9f9;
    margin-bottom: 1rem;
    box-shadow: 0.25rem 0.25rem 0.75rem rgb(0 0 0 / 0.1);
    color: black;
    font-weight: bold;
    padding: 1rem;
    border-radius: 4rem;
    width: calc(100% - 2rem);


    &:nth-child(even) {
      flex-direction: row-reverse;
      background: #eaeaea;
      margin-right: -2rem;
      margin-left: 2rem;
      &::before {
        //background: #ffd507;
      }
    }
  }

  .features-list-icon {
    font-size: 2rem;
    font-weight: 700;
    width: 3rem;
    height: 3rem;
    background: black;
    flex: 0 0 auto;
    border-radius: 50%;
    fill: #ffd507;
    color: #ffd507;
    display: flex;
    justify-content: center;
    align-items: center;

  }


}

.description {
  max-width: 560px;
  .title {
    font-size: 30px;
    margin-top: 1rem;
    margin-bottom:  1rem;
    font-weight: 700;
  }
  .content {
    //border: solid 1px;
    color: #5c5c5c;
    padding: 1rem;
    font-size: 1.5rem;
  }
  &.full {
    max-width: 100%;
  }
}

.slide-img {
    text-align: center;
  img {
    max-width: 300px;
    border: solid 10px ;
    border-radius: 10px;
  }
  &:after {
    display: block;
    min-height: 150px;
    width: 100%;
    content: ""
  }
}

.play-btn-wrap {
 max-width: 500px;
}

.credentials {
  font-size: 12px;
  text-align: end;
}

.bottom-info {
 a {
   color: #d9d9d9;
   svg {
     margin-left: 0.5rem;
   }
 }
}


// --------------------------------------------------------------------------------

.wrap-device {
  align-items: center;
  display: flex;
  justify-content: center;
  &.angle {
    transform: rotate3d(0, 1, 0, 42deg);
    .mobile-device {
      box-shadow: -7px 0 0 #282828, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0;
    } }
}

.mobile-device {
  background: #222222;
  box-shadow: 0 10px 25px rgba(0, 0, 0, .2);
  border-radius: 25px;
  border-bottom: 1px solid #444;
  padding: 15px 6px 6px 6px;
  position: relative;


  .mobile-device-camera {
    width: 20px;
    height: 20px;
    position: absolute;
    content: '';
    background: #444;
    border-radius: 50%;
    border: solid 8px #262525;
    top: 15px;
    left: calc(50% - 10px);


  }

  &::before {
    position: absolute;
    content: '';
    border-bottom: 1px solid #444;
    display: inline-block;
    width: 20%;
    height: 4px;
    background: #000;
    border-radius: 6px;
    left: 40%;
    top: 6px;
  }


}

.screen {
  background: #000 linear-gradient(134deg, #fff, #fff 50%, #efefef 50%);
  max-height: 100%;
  max-width: 320px;
  border-radius: 25px;
  overflow: hidden;
  img {
    max-width: 100%;
  }
}
